import React from 'react'
import { usePostRequest } from '../hooks/request'
import { BORROW_LIST } from '../urls'
import BorrowForm from './BorrowForm'

export default function BorrowCreate({ onCancel, clientId, borrows, borrow }) {
    const products = usePostRequest({ url: BORROW_LIST })

    async function onSubmit(data, action) {
        const { success } = await products.request({ data: { ...data, amount: borrow ? data.amount : -data.amount, client: clientId, currency: data.currency } })

        if (success) {
            action.resetForm()
            borrows.request()
            onCancel()
        }
    }

    return <BorrowForm loading={products.loading} onCancel={onCancel} onSubmit={onSubmit} />
}
