import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { useModal } from '../hooks/modal'
import Layout from '../components/Layout'
import { format } from '../utils/number'
import { useMessage } from '../hooks/message'
import Button from '../components/common/Button'
import NewTable from '../components/common/NewTable'
import { useQueryParams } from '../hooks/queryString'
import SummaryCard from '../components/common/SummaryCard'
import { useLoad, usePostRequest } from '../hooks/request'
import SupplierItemItem from '../components/SupplierItemItem'
import SupplierItemCreate from '../components/SupplierItemCreate'
import { SUPPLIER_DETAIL, SUPPLIER_ITEM_LIST, UPLOAD_FILE_SUPPLIER_ITEM } from '../urls'

export default function SupplierChecksDetail() {
    const { supplierId } = useParams()
    const params = useQueryParams()
    const classDetail = useLoad({ url: SUPPLIER_DETAIL.replace('{id}', supplierId) })
    const className = classDetail.response
    const supplierItems = useLoad({ url: SUPPLIER_ITEM_LIST, params: { supplier: supplierId, ...params } })

    const [showCreateModal, hideCreateModal] = useModal(
        <SupplierItemCreate
            groupName={className ? className.name : ''}
            supplierId={supplierId}
            onSuccess={() => {
                classDetail.request()
                supplierItems.request()
                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()}
        />,
    )

    const navigate = useHistory()
    const [showMessage] = useMessage()

    const { request: postExcel } = usePostRequest({ url: UPLOAD_FILE_SUPPLIER_ITEM.replace('{id}', supplierId) })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            navigate.push('?')
        }
        if (error) showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
    }

    return (
        <Layout>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="is-flex">
                    <Link className="button is-white is-link is-outlined mr-3" to="/supplier">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </Link>
                    <div className="is-size-4">{className?.name}</div>
                </div>
                <div>
                    <label htmlFor="123" style={{ position: 'relative' }}>
                        <Button icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="Импорт" />
                        <input id="123" onChange={handleProductImport} value="" type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                    </label>
                    <Button icon="cloud-upload-outline" className="button is-link is-outlined ml-2 mr-2" text="Экспорт" />
                    <Button onClick={showCreateModal} text="Оплата" icon="cash-outline" className="is-link is-outlined" />
                </div>
            </div>

            <div style={{ display: 'flex', gap: 16, paddingTop: 10 }}>
                <SummaryCard label="Общая выгода USD:" value={`${format(className?.usdComingTotal - className?.usdConsumptionTotal || 0)} $`} />
                <SummaryCard label="Общая выгода Сум:" value={`${format(className?.sumComingTotal - className?.sumConsumptionTotal || 0)} Сум`} />
            </div>

            <br />

            <NewTable
                loading={supplierItems.loading}
                items={supplierItems.response ? supplierItems.response.results : []}
                columns={{ id: '№', name: 'Способ оплаты', phone: 'Примечание', phone_director: 'Дата\t', currency: 'Валюта', come: 'Приход', buttons: 'Расход', actions: '' }}
                totalCount={supplierItems.response ? supplierItems.response.count : 0}
                pageSize={15}
                renderItem={(item, index) => <SupplierItemItem classDetail={classDetail} groupName={className ? className.name : ''} index={index} key={item.id} item={item} supplierItems={supplierItems} />}
            />
        </Layout>
    )
}
