import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { format } from '../utils/number'
import Layout from '../components/Layout'
import { useModal } from '../hooks/modal'
import { useMessage } from '../hooks/message'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import SupplierItem from '../components/SupplierItem'
import SupplierCreate from '../components/SupplierCreate'
import { useLoad, usePostRequest } from '../hooks/request'
import SummaryCard from '../components/common/SummaryCard'
import SearchFilter from '../components/common/SearchFIlter'
import SupplierItemCreate from '../components/SupplierItemCreate'
import { SUPPLIER_ITEM_LIST, SUPPLIER_LIST, UPLOAD_FILE_SUPPLIER } from '../urls'

export default function Supplier() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const suppliers = useLoad({ url: SUPPLIER_LIST, params: { ...params, page } }, [page])
    const supplierItems = useLoad({ url: SUPPLIER_ITEM_LIST })
    const [showCreateModal, setShowCreateModal] = useModal(<SupplierCreate suppliers={suppliers} onCancel={() => setShowCreateModal()} />)

    const allSumComing = suppliers?.response?.results?.reduce((total, supplier) => total + (Number(supplier?.sumComingTotal) || 0), 0)
    const allUsdComing = suppliers?.response?.results?.reduce((total, supplier) => total + (Number(supplier?.usdComingTotal) || 0), 0)
    const allSumComputing = suppliers?.response?.results?.reduce((total, supplier) => total + (Number(supplier?.sumConsumptionTotal) || 0), 0)
    const allUsdComputing = suppliers?.response?.results?.reduce((total, supplier) => total + (Number(supplier?.usdConsumptionTotal) || 0), 0)
    const navigate = useHistory()
    const [showMessage] = useMessage()

    const { request: postExcel } = usePostRequest({ url: UPLOAD_FILE_SUPPLIER })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            navigate.push('?')
        }
        if (error) showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
    }

    const [selectedSupplier, setSelectedSupplier] = useState(null)

    const handleRowClick = (supplier) => {
        setSelectedSupplier(supplier)
    }

    const [showCreateModalPayment, hideCreateModal] = useModal(
        <SupplierItemCreate
            groupName={selectedSupplier ? selectedSupplier.name : ''}
            supplierId={selectedSupplier && selectedSupplier.id}
            onSuccess={() => {
                suppliers.request()
                supplierItems.request()
                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()}
        />,
    )

    const handlePayment = () => {
        if (!selectedSupplier) {
            showMessage('Выберите поставщика', 'is-danger')
            return
        }
        showCreateModalPayment()
    }

    return (
        <Layout>
            <div className="is-flex mb-3">
                <SearchFilter />

                <Button className="button is-link is-outlined ml-2 mr-2" icon="cash-outline" text="Оплата" onClick={handlePayment} />

                <Button icon="cloud-upload-outline" className="button is-link is-outlined ml-2 mr-2" text="Экспорт" />

                <label style={{ position: 'relative' }}>
                    <Button icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="Импорт" />
                    <input value="" onChange={handleProductImport} type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                </label>

                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={showCreateModal} />
            </div>

            <div style={{ display: 'flex', gap: 16, width: '100%', marginBottom: 12, justifyContent: 'flex-end' }}>
                <SummaryCard label="Общая сумма Сум:" value={`${format(allSumComing - allSumComputing || 0)} Сум`} />
                <SummaryCard label="Общая сумма USD:" value={`${format(allUsdComing - allUsdComputing || 0)} $`} />
            </div>

            <Table
                activePage={page}
                onPageChange={setPage}
                loading={suppliers.loading}
                items={suppliers.response ? suppliers.response.results : []}
                columns={{ id: '№', name: 'Имя', phone: 'Телефон', phone_director: 'Телефон директора', phone_accountant: 'Телефон бугалтера', price: 'Количество', buttons: '' }}
                totalCount={suppliers.response ? suppliers.response.count : 0}
                renderItem={(item, index) => <SupplierItem selectedSupplier={selectedSupplier} index={index} key={item.id} item={item} suppliers={suppliers} onClick={() => handleRowClick(item)} />}
            />
        </Layout>
    )
}
