import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { useQueryParams } from '../hooks/queryString'
import { useLoad } from '../hooks/request'
import { CLIENT_DETAIL, ORDER_ARCHIVE_LIST } from '../urls'
import Layout from '../components/Layout'
import DateFilter from '../components/common/DateFilter'
import Table from '../components/common/Table'
import ClientOrderTableItem from '../components/ClientOrderTableItem'
import SearchFilter from '../components/common/SearchFIlter'

export default function ClientOrders() {
    const { clientId } = useParams()
    const params = useQueryParams()
    const classDetail = useLoad({ url: CLIENT_DETAIL.replace('{id}', clientId) })
    const client = useLoad({
        url: ORDER_ARCHIVE_LIST,
        params: { client: clientId, ...params },
    })
    const orderList = client.response?.results || []
    const className = classDetail.response

    return (
        <Layout>
            <header className="is-flex mb-3">
                <div>
                    <NavLink className="button is-white is-link is-outlined mr-2" to="/client">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </NavLink>
                </div>

                <DateFilter className="mr-2" name="date" />
                <SearchFilter />

                <div className="date_filter ml-2" style={{ display: 'flex', gap: '1rem' }}>
                    <DateFilter name="startDate" />
                    <DateFilter name="endDate" />
                </div>
            </header>

            <span className="tag is-info is-light is-medium">
                {className ? className.name : ''} - {className ? className.phoneNumber : ''}
            </span>

            <br />

            <Table columns={{ 1: 'Общая сумма', 2: 'Дата', actions: '' }} items={orderList} renderItem={(item) => <ClientOrderTableItem key={item?.id} item={item} />} />
        </Layout>
    )
}
