import cn from 'classnames'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import Layout from '../components/Layout'
import { useGetRequest, useLoad, usePostRequest } from '../hooks/request'
import { domain, PRODUCT_EXPORT, PRODUCT_LIST, UPLOAD_FILE } from '../urls'
import { useModal } from '../hooks/modal'
import ProductCreate from '../components/ProductCreate'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Table from '../components/common/Table'
import CheckCreate from '../components/CheckCreate'
import Search from '../components/Search'
import Button from '../components/common/Button'
import { useMessage } from '../hooks/message'
import DateFilter from '../components/common/DateFilter'
import ItemProduct from './products/Item'

export default function Products() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const products = useLoad({ url: PRODUCT_LIST, params: { page, ...params, status: 'active' } }, [params, page])
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })

    const [showCreateModal, setShowCreateModal] = useModal(<ProductCreate reload={products} onCancel={() => setShowCreateModal()} />, styles.modal)
    const [showCreateCheck, hideCreateCheck] = useModal(<CheckCreate reload={check} onCancel={() => hideCreateCheck()} />)

    const history = useHistory()
    const [showMessage] = useMessage()

    const { request: postExcel } = usePostRequest({ url: UPLOAD_FILE })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            history.push('?')
        }
        if (error) {
            showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
        }
    }

    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <div className="is-flex mb-3">
                <Search onSearch={onSearch} />

                <div className="date_filter ml-2" style={{ display: 'flex', gap: '1rem' }}>
                    <DateFilter name="startDate" />
                    <DateFilter name="endDate" />
                </div>

                <Button onClick={excelProduct.request} icon="cloud-upload-outline" className="button is-link is-outlined ml-2 mr-2" text="Экспорт" />

                <label style={{ position: 'relative' }}>
                    <Button icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="Импорт" />
                    <input onChange={handleProductImport} value="" type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                </label>

                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={showCreateModal} />
            </div>

            <div className="mt-4">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (
                    <Table
                        loading={products.loading}
                        items={productList}
                        columns={{ index: 'No', image: 'Изображение', name: 'Наименование товара', count: 'Количество', barcode: 'Штрих-код', category: 'Категория', actions: 'Действие' }}
                        totalCount={products.response ? products.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <ItemProduct index={index} products={products} check={check} key={item.id} item={item} />}
                    />
                )}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' }, emptyImg: { width: '330px', height: '300px' }, loader: { marginTop: '2rem' }, modal: { width: 800 }, checkmark: { marginRight: '3px' } })
