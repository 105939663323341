import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { getCurrency, getMeasurement, getTradeTypeName } from '../../utils/types'
import { format } from '../../utils/number'
import Button from '../../components/common/Button'
import { CustomPrintBarCode } from './custom-print'

export default function Confirmation({ onCancel, onConfirm, values, totals, loading, cash }) {
    console.log(values, 'salom')

    const componentRef = useRef()

    const handlePrint = useReactToPrint({ content: () => componentRef.current })

    return (
        <>
            {values?.client && (
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>Клиент:</b>
                    <span style={{ fontWeight: 500 }}>{values.client?.name}</span>
                </p>
            )}
            <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                <b>Тип торговли:</b>
                <span style={{ fontWeight: 500 }}>{getTradeTypeName(values.tradeType)}</span>
            </p>
            <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                <b>Валюта:</b>
                <span style={{ fontWeight: 500 }}>{getCurrency(values.currency)}</span>
            </p>
            <p style={{ fontWeight: 'bold' }}>Продукты:</p>
            <div style={{ height: 1, backgroundColor: 'black', margin: '8px 0' }} />
            {values?.products?.map((prod, i) => (
                <div key={prod?.id} style={{ marginBottom: '1rem' }}>
                    <h3 style={{ fontWeight: 'bold' }}>
                        {i + 1}. {prod?.name}
                    </h3>

                    <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                        <span>Цена:</span>
                        <span style={{ fontWeight: 500 }}>
                            {prod?.quantity} {getMeasurement(prod?.measurement)} x {format(values.currency === 'sum' ? prod?.sumPriceSale : prod.usdPriceSale) || 0} {values.currency === 'sum' ? 'Сум' : 'USD'} ={' '}
                            <b>
                                {format(prod?.quantity * (values.currency === 'sum' ? prod?.sumPriceSale : prod.usdPriceSale))} {values.currency === 'sum' ? 'Сум' : 'USD'}
                            </b>
                        </span>
                    </p>
                </div>
            ))}

            <div style={{ height: 1, backgroundColor: 'black', margin: '8px 0' }} />

            <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                <span style={{ fontWeight: 'bold' }}>Итого:</span>
                <b>
                    {format(totals.totalSaleSum)} {values.currency === 'sum' ? 'Сум' : 'USD'}
                </b>
            </p>

            <div style={{ display: 'none' }}>
                <CustomPrintBarCode result={values?.cash} item={values} ref={componentRef} />
            </div>

            <div style={{ marginTop: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                <Button
                    onClick={() => {
                        onConfirm()
                        handlePrint()
                    }}
                    text="Подтвердить"
                    className="is-primary"
                    loading={cash.loading}
                    disabled={loading}
                />
                <Button onClick={onCancel} text="Отмена" className="is-outlined" disabled={loading} />
            </div>
        </>
    )
}
