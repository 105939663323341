import React from 'react'
import { usePostRequest } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import { useMessage } from '../hooks/message'
import DocumentsProductForm from './DocumentsProductForm'

export default function DocumentsProductCreate({ onCancel, refresh, arrayHelpers }) {
    const [showMessage] = useMessage()
    const post = usePostRequest({ url: PRODUCT_LIST })

    async function onSubmit(data) {
        const { success, error, response } = await post.request({ data })
        if (success) {
            refresh()
            onCancel()
            showMessage('Успешно добавлен', 'is-success')
            const items = JSON.parse(localStorage.getItem('items') || '[]')
            localStorage.setItem('items', JSON.stringify([...items, { ...response, quantity: '', price: '', priceSale: '', priceTransfer: '' }]))
            arrayHelpers.push({ ...response, quantity: '', price: '', priceSale: '', priceTransfer: '' })
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Добавить товар</b>
            </h1>

            <DocumentsProductForm onCancel={onCancel} onSubmit={onSubmit} loading={post.loading} />
        </div>
    )
}
