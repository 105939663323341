/* eslint-disable */
import React, { forwardRef } from 'react'
import { format } from '../../utils/number'
import { getDateTime } from '../../utils/date'

export const CustomPrintBarCode = forwardRef(({ item, result, lend, paidAmount }, ref) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid black', width: 800, padding: 20 }}>
            <div ref={ref} style={{ width: '100%', padding: 20 }}>
                <div style={{ textAlign: 'center', marginTop: 8, fontSize: '18px' }}>
                    <strong>БЕК СТРОЙ МАРКЕТ </strong>
                    <br />
                    Телефон: {item?.client?.phoneNumber}
                    <br />
                    Адрес: МАХАЛАГИЖА
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Кассир: </div>
                    <div>Feruzjon Xayrulloyev</div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>ИНН: </div>
                    <div>1730696809207626</div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Дата: </div>
                    <div>{getDateTime(new Date())}</div>
                </div>

                <div style={{ marginTop: 8, borderTop: '1px solid #eee', paddingTop: 8 }}>
                    <div style={{ display: 'flex', fontWeight: 'bold', marginBottom: 4 }}>
                        <div style={{ width: '50%' }}>№ Продукт</div>
                        <div style={{ width: '25%' }}>Количество</div>
                        <div style={{ width: '25%', textAlign: 'right' }}>Цена</div>
                    </div>
                    {item?.products?.map((elem, i) => (
                        <div key={i} style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', padding: '4px 0' }}>
                            <div style={{ width: '50%' }}>
                                {i + 1}. {elem?.name}
                            </div>
                            <div style={{ width: '25%' }}>
                                {Number(elem?.quantity)} x {format(elem?.sumPriceSale || 0)}
                            </div>
                            <div style={{ width: '25%', textAlign: 'right' }}>{format(Number(elem?.quantity) * (elem?.sumPriceSale || 0))}</div>
                        </div>
                    ))}
                </div>

                <div
                    style={{
                        borderTop: '1px solid #eee',
                        borderBottom: '1px solid #eee',
                        marginTop: 8,
                        paddingTop: 8,
                        paddingBottom: 8,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                        }}
                    >
                        <span>Сумма продаж:</span>
                        <span>
                            {format(result)} {item.currency === 'sum' ? 'Сум' : 'USD'}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                        }}
                    >
                        <span>Скидка:</span>
                        <span>0</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 'bolder',
                            fontSize: '20px',
                            marginTop: 8,
                        }}
                    >
                        <span>К оплате:</span>
                        <span>
                            {format(result)} {item.currency === 'sum' ? 'Сум' : 'USD'}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                            marginTop: 4,
                        }}
                    >
                        <span>Оплачено:</span>
                        <span>
                            {format(paidAmount)} {item.currency === 'sum' ? 'Сум' : 'USD'}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                            marginTop: 4,
                        }}
                    >
                        <span>Налички:</span>
                        <span>
                            {format(paidAmount)} {item.currency === 'sum' ? 'Сум' : 'USD'}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                            marginTop: 4,
                        }}
                    >
                        <span>Сдача:</span>
                        <span>0</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                            marginTop: 4,
                        }}
                    >
                        <span>Сумма долга:</span>
                        <span>
                            {lend || item?.totalForOrderHistories} {item.currency === 'sum' ? 'Сум' : 'USD'}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                            marginTop: 4,
                        }}
                    >
                        <span>Должник:</span>
                        <span>{item?.client?.name}</span>
                    </div>
                </div>

                <div style={{ textAlign: 'center', marginTop: 40, fontSize: '18px' }}>Спасибо за покупку</div>
            </div>
        </div>
    )
})
