import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useQueryParams } from '../../hooks/queryString'
import Loader from './Loader'
import Pagination from './Pagination'

export default function NewTable({ loading = false, emptyMessage = 'Пусто', showEmptyMessage = true, totalCount = 0, pageSize = 15, items, columns, renderItem, className = 'table is-striped is-fullwidth', emptyMessageColor = null, pageKey = 'page' }) {
    const history = useHistory()
    const params = useQueryParams()
    const currentPage = params[pageKey] ?? 1
    const onPageChange = (page) => {
        history.push(`?${queryString.stringify({ ...params, [pageKey]: page })}`)
    }

    if (loading) {
        return (
            <div className={css(styles.space)}>
                <Loader large center />
            </div>
        )
    }

    if (isEmpty(items) && showEmptyMessage) {
        const style = cn(emptyMessageColor || 'has-text-grey', 'is-size-5 has-text-centered', css(styles.space))
        return <div className={style}>{emptyMessage}</div>
    }

    return (
        <div>
            <table className={className}>
                <tbody>
                    {columns ? (
                        <tr>
                            {Object.entries(columns).map(([key, value]) => (
                                <th key={key}>{value}</th>
                            ))}
                        </tr>
                    ) : null}

                    {items.map(renderItem)}
                </tbody>
            </table>

            <Pagination currentPage={+currentPage} totalCount={totalCount} pageSize={pageSize} onPageChange={onPageChange} />
        </div>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' } })
